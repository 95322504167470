import "./utils/simple-modal";

jQuery(document).ready(function ($) {
  // TOGGLE HAMBURGER & COLLAPSE NAV
  $(".nav-toggle").on("click", function () {
    $(this).toggleClass("open");
    $(".navbar").toggleClass("collapse");
  });
  // REMOVE X & COLLAPSE NAV ON ON CLICK
  $(".navbar a").on("click", function () {
    $(".nav-toggle").removeClass("open");
    $(".navbar").removeClass("collapse");
  });

  $(".wp-block-media-text").addClass("sk-container sk-container--sm");

  $(".button-cta").on("click", function () {
    $("#modal-primary .modal-title").text($(this).data("title"));
    $("#modal-primary input[name='modalform-modalname']").val(
      $(this).data("title")
    );
  });

  
  document.addEventListener("wpcf7mailsent", function () {
    setTimeout(function () {
      window.location.href = "/thanks/";
    }, 1);
  });
  
  $(window).scroll(function () {
    $(this).scrollTop() > 400
      ? $(".top__btn").fadeIn()
      : $(".top__btn").fadeOut();

    $(this).scrollTop() > 300
      ? $("header.header").addClass("fixed-header")
      : $("header.header.fixed-header").removeClass("fixed-header");
  });

  $(".top__btn, .logo_top__btn").on("click", function () {
    return (
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        300
      ),
      !1
    );
  });

  $(".phons-nav-toggle").on("click", function () {
    $(".header-navigation__contacts").toggleClass("open-top-phons");
  });

  $(".filters-trigger").on("click", function () {
    $(".projects-filters").toggleClass("openned-filters");
  });

  $(".facetwp-reset-btn").on("click", function () {
    $(".projects-filters").removeClass("openned-filters");
  });

  if ($("input[name='modalform-page']").length) {
    $("input[name='modalform-page']").each(function (index, item) {
      if ($(".home-hello-hero").length) {
        $(item).val("Головна сторінка");
      }
      if ($(".page-post-header h1").length) {
        $(item).val($(".page-post-header h1").text());
      }
      if ($("h1.project-title").length) {
        $(item).val($("h1.project-title").text());
      }
      if ($("h1.service-title").length) {
        $(item).val($("h1.service-title").text());
      }

      //if(parseInt($(item).data('index'))>2){
      //   $(item).html('Testimonial '+(index+1)+' by each loop');
      // }
    });
  }

  //$('a.current-menu-item').removeAttr('href');
  $("#main-footer-text").html($("#main-top-text").html());
  $("#main-top-text").html("");

  /* swing scroling */
  // direct browser to top right away
  if (window.location.hash) scroll(0, 0);
  // takes care of some browsers issue
  setTimeout(function () {
    scroll(0, 0);
  }, 1);

  /*Scroll transition to anchor*/
  $(".scroll-menu-link > a").on("click", function (e) {
    var url = e.target.href;
    var hash = url.substring(url.indexOf("#") + 1);

    if ($("#" + hash).offset()) {
      $("html, body").animate(
        {
          scrollTop: $("#" + hash).offset().top - 75,
        },
        500
      );
      return false;
    }
  });

  // if we have anchor on the url (calling from other page)
  if (window.location.hash) {
    $("html,body").animate(
      {
        scrollTop: $(window.location.hash).offset().top + "px",
      },
      1000,
      "swing"
    );
  }
  /* load */
});

window.addEventListener("resize", () => {});

